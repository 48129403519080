import React, { useState, useEffect } from 'react';
import "./App.css";
import techlogPage from "./techlogpage.jpg";

function App() {
  const [arrivalValues, setArrivalValues] = useState([0, 0, 0]);
  const [arrivalTotal, setArrivalTotal] = useState(0);
  const [isArrivalTotalModified, setIsArrivalTotalModified] = useState(false);

  const [planBlock, setPlanBlock] = useState(0);

  const [postUpliftValues, setPostUpliftValues] = useState([0, 0, 0]);
  const [postUpliftTotal, setPostUpliftTotal] = useState(0);

  const [sg, setSg] = useState(0.790);

  const [planUpliftLiters, setPlanUpliftLiters] = useState(0);
  const [planUpliftKg, setPlanUpliftKg] = useState(0);

  const [upliftLiters, setUpliftLiters] = useState(0);
  const [upliftKg, setUpliftKg] = useState(0);

  const [diffKg, setDiffKg] = useState(0);

  const [showFullScreen, setShowFullScreen] = useState(false);

  useEffect(() => {
    setPlanUpliftKg(planBlock - arrivalTotal);
    setPlanUpliftLiters(Math.floor(planUpliftKg / sg));
    setUpliftKg(Math.floor(upliftLiters * sg));
    setDiffKg(Math.abs(planUpliftKg - upliftKg));
  }, [arrivalTotal, sg, planUpliftKg, upliftLiters, upliftKg, planBlock]);

  const handleChangeArrival = (index, value) => {
    if (isArrivalTotalModified && arrivalTotal === 0) {
      setIsArrivalTotalModified(false);
    }

    const newValues = [...arrivalValues];
    newValues[index] = parseInt(value) || 0;
    setArrivalValues(newValues);
    const newTotal = newValues.reduce((acc, curr) => acc + (isNaN(curr) ? 0 : curr), 0);
    setArrivalTotal(newTotal);
  };

  const handleTotalChangeArrival = (e) => {
    const newTotal = parseInt(e.target.value) || 0;
    setArrivalTotal(newTotal);
    setIsArrivalTotalModified(true);
    setArrivalValues(["#", "#", "#"]);
  };

  const handleSg = (action) => {
    if (action === 'increase') {
      setSg(sg + 0.001);
    } else if (action === 'decrease') {
      setSg(sg - 0.001);
    }
  };

  const handleChangeUpliftLiters = (event) => {
    setUpliftLiters(parseInt(event.target.value) || 0);
  };

  const handleChangePlanBlock = (event) => {
    setPlanBlock(parseInt(event.target.value) || 0);
  };

  const handleChangePostUplift = (index, value) => {
    const newValues = [...postUpliftValues];
    newValues[index] = parseInt(value) || 0;
    setPostUpliftValues(newValues);
    const newTotal = newValues.reduce((acc, curr) => acc + (isNaN(curr) ? 0 : curr), 0);
    setPostUpliftTotal(newTotal);
  };

  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  const resetValues = () => {
    setArrivalValues([0, 0, 0]);
    setArrivalTotal(0);
    setIsArrivalTotalModified(false);
    setPlanBlock(0);
    setPostUpliftValues([0, 0, 0]);
    setPostUpliftTotal(0);
    setSg(0.790);
    setPlanUpliftLiters(0);
    setPlanUpliftKg(0);
    setUpliftLiters(0);
    setUpliftKg(0);
    setDiffKg(0);
  };

  return (
    <div className="app">
      <div className="fieldsWithBackground">
        <img src={techlogPage} className="background-image" alt="Techlog Page" />
        {arrivalValues.map((value, index) => (
          <input
            key={`arrival-${index}`}
            className={`numberField arrival${index === 1 ? 'Center' : index === 2 ? 'Right' : 'Left'} ${value > (index === 1 ? 13066 : 3915) ? 'red' : ''}`}
            type="text"
            pattern="[0-9]*"
            maxLength={index === 1 ? 5 : 4}
            value={value}
            onChange={(e) => handleChangeArrival(index, e.target.value)}
            disabled={isArrivalTotalModified && arrivalTotal !== 0}
          />
        ))}
        <input
          className={`numberField arrivalTotal ${arrivalTotal > 20898 ? 'red' : ''}`}
          type="text"
          pattern="[0-9]*"
          maxLength="5"
          value={arrivalTotal}
          onChange={handleTotalChangeArrival}
        />
        <div>
          <small className="planBlockTitle">Plan Block</small>
          <input
            className="numberField planBlock"
            type="text"
            pattern="[0-9]*"
            maxLength="5"
            value={planBlock}
            onChange={handleChangePlanBlock}
          />
          <svg fill="#fff" onClick={toggleFullScreen} className="fullScreenButton" version="1.1" viewBox="0 0 512 512">
            <g>
              <g>
                <path d="M0,0v512h512V0H0z M477.867,477.867H34.133V34.133h443.733V477.867z" />
              </g>
            </g>
            <g>
              <g>
                <polygon points="126.533,102.4 199.111,102.4 199.111,68.267 68.267,68.267 68.267,199.111 102.4,199.111 102.4,126.538 
      198.422,222.558 222.556,198.423    " />
              </g>
            </g>
            <g>
              <g>
                <polygon points="222.557,313.581 198.422,289.445 102.4,385.467 102.4,312.889 68.267,312.889 68.267,443.733 199.111,443.733 
      199.111,409.6 126.538,409.6    " />
              </g>
            </g>
            <g>
              <g>
                <polygon points="409.6,312.889 409.6,385.467 313.578,289.444 289.444,313.578 385.462,409.6 312.889,409.6 312.889,443.733 
      443.733,443.733 443.733,312.889    " />
              </g>
            </g>
            <g>
              <g>
                <polygon points="312.889,68.267 312.889,102.4 385.467,102.4 289.444,198.423 313.578,222.558 409.6,126.538 409.6,199.111 
      443.733,199.111 443.733,68.267    " />
              </g>
            </g>
          </svg>
        </div>
        {postUpliftValues.map((value, index) => (
          <input
            key={`postUplift-${index}`}
            className={`numberField postUplift${index === 1 ? 'Center' : index === 2 ? 'Right' : 'Left'} ${value > (index === 1 ? 13066 : 3915) ? 'red' : ''}`}
            type="text"
            pattern="[0-9]*"
            maxLength={index === 1 ? 5 : 4}
            value={value}
            onChange={(e) => handleChangePostUplift(index, e.target.value)}
          />
        ))}
        <div className={`numberField postUpliftTotal ${postUpliftTotal > 20898 ? 'red' : ''}`}>
          {postUpliftTotal}
        </div>
        <div>
          <div className="numberField sg">{sg.toFixed(3)}</div>
          <div className="sgButton sgButtonInc" onClick={() => handleSg('increase')}>+</div>
          <div className="sgButton sgButtonDec" onClick={() => handleSg('decrease')}>-</div>
        </div>
        <div className="numberField planUpliftLiters">
          {planUpliftLiters < 0 ? '#' : planUpliftLiters}
        </div>
        <div className="numberField planUpliftKg">
          {planUpliftKg < 0 ? '#' : planUpliftKg}
        </div>
        <input
          className="numberField upliftLiters"
          type="text"
          pattern="[0-9]*"
          maxLength="5"
          value={upliftLiters}
          onChange={handleChangeUpliftLiters}
        />
        <div className="numberField upliftKg">
          {upliftKg}
        </div>
        <div className="numberField diffKg" style={{ color: diffKg >= 0 && diffKg <= 200 ? 'green' : 'red', display: planBlock === 0 ? 'none' : 'block' }}>
          {diffKg}
        </div>
        <button className="resetButton" onClick={resetValues}>Reset</button>
      </div>

      {showFullScreen && (
        <div className="fullScreenOverlay" onClick={toggleFullScreen}>
          <div className="fullScreenContent">{planBlock}</div>
        </div>
      )}
    </div>
  );
}

export default App;
